import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Navbar from "./Navbar/Navbar"
import Img from "gatsby-image"

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query {
    logo2: file(relativePath: { eq: "porphyries.png" }) {
      childImageSharp {
        fixed(width: 344, height: 83) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`)

  const rootPath = `${__PATH_PREFIX__}`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <p className="main-heading">
        <Link to="/">{title}</Link>
      </p>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <>
    <Navbar />
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header"><h1 align="center"><Img fixed={data.logo2.childImageSharp.fixed} alt="porphyries" />{header}</h1></header>
      <main>{children}</main>
      <footer>
        2009 © {new Date().getFullYear()},
        {` `}
        <a href="http://www.porphyries-patients.org/">porphyries-patients.org</a>
      </footer>
    </div>
    </>
  )
}

export default Layout
